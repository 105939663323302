<template>
  <div class="page-manage  ">
    <div class="page-manage-container  box-shadow-light-grey">
      <div class="table-info border-radius-6 padding-20">
        <div class="search-and-operation padding-bottom-20">
          <div class="left-operation">
            <el-button @click="refreshData">刷新数据</el-button>
            <el-button
              class="custom-button-margin-left"
              type="primary"
              @click="createPage"
              >新建推广页</el-button
            >
            <el-button
              class="custom-button-margin-left"
              type="danger"
              plain
              :disabled="selectionList.length === 0"
              @click="deleteSelectedPage"
              >批量删除</el-button
            >
            <el-input
              class="margin-left-twentyFour"
              v-model="search"
              clearable
              placeholder="请输入推广页名称"
              style="width:300px"
              @clear="resetData"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
            <el-input
              class="margin-left-twentyFour"
              v-model="selected"
              clearable
              @clear="handleClear"
              placeholder="团队/用户"
              style="width:150px"
              @focus="chooseDeptOrUser"
            ></el-input>
            <select-dept-user
              :show="isShowDeptUser"
              @close="isShowDeptUser = false"
              @confirm="confirm"
            />
            <el-button
              class="custom-button-margin-left"
              type="primary"
              @click="handleSearch"
              >查询</el-button
            >
            <el-button
              class="custom-button-margin-left"
              type="info"
              plain
              @click="resetData"
              >重置</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            :data="dataList"
            height="680px"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed="left">
            </el-table-column>
            <el-table-column
              label="推广页ID"
              prop="id"
              width="80"
            ></el-table-column>
            <el-table-column
              label="推广页名称"
              prop="canvasName"
            ></el-table-column>
            <el-table-column label="用户名" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.adminUser.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="背景色">
              <template slot-scope="scope">
                <div :style="getBackground(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column label="分享标题">
              <template slot-scope="scope">
                <div class="show-suspension">
                  {{ scope.row.shareTitle }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="分享描述">
              <template slot-scope="scope">
                <div class="show-suspension">
                  {{ scope.row.shareDesc }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="创建时间"
              prop="createdTime"
              width="200"
            ></el-table-column>
            <el-table-column
              label="最后修改时间"
              prop="updatedTime"
              width="200"
            ></el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button
                  class="button-small"
                  type="info"
                  size="mini"
                  @click="copyPage(scope.row)"
                >
                  复制
                </el-button>
                <el-button
                  class="button-small"
                  type="primary"
                  size="mini"
                  @click="editPage(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  class="button-small"
                  type="danger"
                  size="mini"
                  @click="deletePage(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="padding: 20px; text-align: right"
            background
            layout="total, prev, pager, next, jumper"
            :page-size="pageSize"
            :total="total"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getWeChatPage,
  deleteWeChatPage,
  getWeChatPageDetail,
  addWeChatPage
} from '@/api/novelPut'
import { mapMutations } from 'vuex'
import SelectDeptUser from '@/views/statistics/components/SelectDeptUser.vue'
export default {
  name: 'pageManage',
  data () {
    return {
      search: '',
      dataList: [],
      pageSize: 13,
      page: 1,
      total: 0,
      loading: true,
      selectionList: [], // 被选中的表格行合集
      selected: '',
      isShowDeptUser: false,
      adminUserId: null,
      deptId: null,
      copyPageData: null,
      pageId: null,
      copyNumTag: 1
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapMutations(['resetAllModule', 'setIsPageUpdate']),
    initData () {
      this.loading = true
      getWeChatPage({
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        adminUserId: this.adminUserId,
        deptId: this.deptId
      }).then(res => {
        this.total = res.total
        this.dataList = res.list
        this.loading = false
      })
    },
    createPage () {
      window.localStorage.removeItem('bgColor')
      this.setIsPageUpdate(false)
      this.resetAllModule()
      this.$router.push({
        name: 'PromotionPage'
      })
    },
    editPage (value) {
      const { id } = value
      this.setIsPageUpdate(true)
      this.$router.push({
        name: 'PromotionPage',
        params: {
          id
        }
      })
    },
    copyPage (value) {
      const { id } = value
      if (this.pageId !== id) {
        this.copyNumTag = 1
        this.pageId = id
      }

      getWeChatPageDetail(id).then(res => {
        const {
          canvasName,
          shareDesc,
          shareTitle,
          backgroundColor,
          items
        } = res
        const newCanvasName = `${canvasName}-副本${this.copyNumTag}`
        this.$prompt('请输入推广页名称', '复制', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'text',
          inputValue: newCanvasName
        })
          .then(res => {
            if (newCanvasName.length > 30) {
              this.$message({
                showClose: true,
                message: '当前推广页的标题过长，请手动修改后在进行复制!',
                type: 'error'
              })
              return
            }
            addWeChatPage({
              canvasName: res.value,
              shareDesc,
              shareTitle,
              backgroundColor,
              items
            }).then(() => {
              this.$message({
                showClose: true,
                message: '复制成功!',
                type: 'success'
              })
              this.copyNumTag = this.copyNumTag + 1
              this.page = 1
              this.initData()
            })
          })
          .catch(() => {})
      })
    },
    deletePage (value) {
      const { id } = value
      this.$confirm('删除之后将无法恢复，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteWeChatPage(id).then(() => {
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success'
          })
          this.page = 1
          this.initData()
        })
      })
    },
    deleteSelectedPage () {
      const ids = this.selectionList.map(item => item.id).join(',')
      deleteWeChatPage(ids).then(res => {
        this.$message({
          showClose: true,
          message: '删除成功!',
          type: 'success'
        })
        this.page = 1
        this.initData()
      })
    },
    handleSearch () {
      this.page = 1
      this.initData()
    },
    handleSelectionChange (section) {
      this.selectionList = section
    },
    handlePageChange (page) {
      this.page = page
      this.initData()
    },
    refreshData () {
      // this.page ;
      this.initData()
    },
    getBackground (row) {
      return {
        backgroundColor: `${row.backgroundColor}`,
        height: '30px',
        width: '100px'
      }
    },
    handleClear () {
      this.adminUserId = null
      this.deptId = null
      this.initData()
    },
    chooseDeptOrUser () {
      this.isShowDeptUser = true
    },
    confirm (selected) {
      const { id, type } = selected
      if (type === 1) {
        this.adminUserId = id
        this.deptId = null
      }
      if (type === 0) {
        this.adminUserId = null
        this.deptId = id
      }
      this.selected = selected.name
      // if (selected.type === 1) {
      //   this.$set(this.params, 'adminUserId', selected.id)
      // } else if (selected.type === 0) {
      //   this.$set(this.params, 'deptId', selected.id)
      // }
    },

    resetData () {
      this.search = ''
      this.page = 1
      this.initData()
    }
  },
  components: {
    SelectDeptUser
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}
.show-suspension {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-manage {
  .page-manage-container {
    box-sizing: border-box;
    .search-and-operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      // padding: 0 15px;

      box-sizing: inherit;
    }
    .table-info {
      background: #fff;
      // margin-top: 10px;
      // padding: 15px;
      box-sizing: inherit;
      // height: 760px;
    }
  }
}
</style>
